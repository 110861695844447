<template>
  <div>
    <div class="page-title-area page-title-area-padding bg-6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2 v-if="program_type">{{program_type.data[0].name}}</h2>
          <ul>
            <li class="active">{{program_data.category}}</li>
          </ul>
        </div>
      </div>
    </div>

    <section id="about" class="about-section ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-area-content">
              <h3>{{program_data.name}}</h3>
            </div>

            <div class="about-tab mt-5">
              <p v-html="program_data.description" class="white-space-pre-wrap"></p>
            </div>

            <div class="about-tab">
              <div class="tab about-list-tab">
                <ul class="tabs">
                  <li class="current" v-if="program_data.button_url">
                    <a :href="program_data.button_url" target="_blank" rel="noreferrer">
                      <i class="flaticon-study"></i>
                      {{program_data.button_text}}
                    </a>
                  </li>
                  <li v-if="program_file">
                    <a :href="program_file.data.data.full_url" target="_blank" rel="noreferrer">
                      <i class="flaticon-curve-arrow"></i>
                      Download Info
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="about-image">
              <DefaultImage :id="program_data.image" :height="'567px'" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import DefaultImage from "../DefaultImage";
  import api from "../../services/api";
  import useSWRV from "swrv";

  export default {
    name: "ProgramsSlug",
    props: ['program_data'],
    setup(props) {

      console.log(props)
      const {
        data: program_type,
      } = useSWRV(() => `items/program_type?status=published&filter[id]=${props.program_data.program_type}`, api.fetcher);

      if (props.program_data.file) {
        var {
          data: program_file,
        } = useSWRV(() => `/files/${props.program_data.file}?status=published`, api.fetcher);
      }

      return {
        program_type,
        program_file,
      };
    },
    components: {DefaultImage}
  }
</script>

<style scoped>
  .page-title-area .page-title-content ul .active {
    font-size: 20px;
  }
  .page-title-area-padding {
    padding-top: 90px;
    padding-bottom: 75px;
  }
</style>
