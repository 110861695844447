<template>
  <div id="program-detail">
    <div style="height: 1000px" class="loading" v-if="program_data === undefined && !program_error">
      <Loading />
    </div>
    <ProgramsSlug :program_data="program_data.data[0]" v-if="program_data" />

    <div id="eventsArea">
      <div style="height: 951px" class="loading" v-if="events_data === undefined && !events_error">
        <Loading />
      </div>
      <EventsArea v-if="events_data" :events="events_data.data" />
    </div>
  </div>
</template>

<script>
  import EventsArea from "../components/EventsArea";
  import Loading from "../components/Loading";
  import api from "../services/api";
  import useSWRV from "swrv";
  import moment from "moment";
  import ProgramsSlug from "../components/programs/ProgramsSlug";

  export default {
    name: "ProgramDetail",
    setup(props, { root }) {
      if (!root.$route.params.url) {
        root.$router.push({ name: '/' });
      }
      const {
        data: program_data,
        error: program_error,
        isValidating: program_isValidating
      } = useSWRV(() => `items/programs?status=published&filter[slug]=${root.$route.params.url}`, api.fetcher);

      const {
        data: events_data,
        error: events_error,
        isValidating: events_isValidating
      } = useSWRV(() => `items/events?status=published&filter[end_date][gte]=${moment().format('YYYY-MM-DD')}&sort=end_date&limit=3`, api.fetcher);

      return {
        program_data,
        program_error,
        program_isValidating,

        events_data,
        events_error,
        events_isValidating
      };
    },
    async mounted() {
      window.scrollTo(0, 0);
    },
    components: {
      ProgramsSlug,
      Loading,
      EventsArea
    },
  }
</script>

<style scoped>

</style>
